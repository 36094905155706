<template>
  <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div>
      <switchProfilePin
        v-if="showSwitchProfilePin"
        :profile="selectedProfile"
        @closeProfilePinPopup="closeProfilePinPopupEvent"
        :closePopupEvent="closeProfilePinPopupEvent"
        :isAddProfile="isAddProfile"
        @onForgotPin="onActionForgotPin"
      />
    </div>

    <ForgotPinModal
      id="pc-forgot-pin-modal-profile"
      :open="showForgotPinModal"
      @onClose="closeForgotPinModal"
      @onActionComplete="onForgotPinModalActionComplete"
      :pinError="pinError"
      @onResend="resendOtp"
      :loading="loading"
    />

    <div>
      <SetOrVerifyProfilePin
        v-if="showSetOrVerifyProfilePin"
        :profile="selectedProfile"
        :isSetNewPin="isSetNewPin"
        @closeProfilePinPopup="closeSetOrVerifyProfilePin"
        :closePopupEvent="closeSetOrVerifyProfilePin"
      />
    </div>
    <img v-if="renderCancel" src="../../assets/icons/close_with_shadow.svg" class="close-icon-switch-profile" @click="actClosePopup"/>

    <div class="switchProfileContainer">

      <div v-if="isLoading">
        <Loading></Loading>
      </div>
      <div v-if="!isLoading">
        <p class="whosWatching">{{ $t("Who's watching") }}</p>
      </div>
      <div v-if="!isLoading" class="switchProfileCard">
        <!-- Profiles -->
        <div
          v-for="profile in profilesList"
          @click="selectProfile(profile)"
          :key="profile.profileid"
          class="user-profile"
        >
          <img v-if="!profile.picture" src="@/assets/icons/Generic.png" class="userImage" alt="" />
          <img v-if="profile.picture" :src="profile.picture" class="userImage" alt="" />
          <p class="userName">{{ profile.profilename }}</p>
          <p v-if="profile.profileid === subscriberid" class="userAsAdmin">{{ $t("admin") }}</p>
          <p v-if="profile.kidsmode === 'YES'" class="userAsAdmin">{{ $t("kids") }}</p>
        </div>

        <!-- Add new Profile -->
        <div v-if="showAddProfileButton && renderCancel" class="user-profile">
          <img src="@/assets/icons/add.png" class="userImage" alt="" @click="goToAddProfile" />
          <p class="userName">{{ $t("add profile") }}</p>
        </div>
      </div>

      <div class="loader-margin" v-if="isProfileLoading"><Loading /></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { showSnackBar } from "@/utilities";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import Utility from "@/mixins/Utility.js";
import jwt_decode from "jwt-decode";
import { getFreshTokenUpdate, actSaveExpireTime } from '@/store/refershTokenInterval'
import { eventBus } from "@/eventBus";

export default {
  props: {
    mode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      profilesList: null,
      isLoading: false,
      localDisplayLang: null,
      showSwitchProfilePin: false,
      selectedProfile: null,
      isProfileLoading: false,
      showSetOrVerifyProfilePin: false,
      isSetNewPin: false,
      subscriberProfileDetails: null,
      noOfUserProfilesLimit: 5,
      isAddProfile: false,
			currentProfileIdAdminCheck : null,
      showForgotPinModal: false,
      pinError: "",
      loading: false,
      forgotPinProfileContext: null,
      renderCancel: true,
    };
  },

  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/overlayLoader.vue"),
    switchProfilePin: () =>
      import(/* webpackChunkName: "switchProfilePin" */ "@/components/Popups/ProfilePopups/switchProfilePin.vue"),
    SetOrVerifyProfilePin: () =>
      import(/* webpackChunkName: "switchProfilePin" */ "@/components/Profile/SetOrVerifyProfilePin.vue"),
    ForgotPinModal: () => import("@/components/utils/ForgotPinModal.vue"),
  },

  computed: {
    ...mapGetters(["subscriberid", "profileid", "appConfig", "getPlanDeeplinkPath", "subscriptionList"]),
    showAddProfileButton() {
  if (
    this.subscriberid === this.profileid &&
    this.profilesList &&
    this.profilesList.length < this.noOfUserProfilesLimit
  ) {
    this.profilesList.sort((a, b) => {
      if (a.isAdmin) return -1;
      if (b.isAdmin) return 1;
      return 0;
    });
    return true;
  } else {
    return false;
  }
}
     },
  destroyed(){
    let header =  document.getElementById("headerMobilePhoneWise");
    header.classList.remove('display-none');
  },

  created() {
    // if (this.appConfig && this.appConfig.defaultProfileLimit) {
    //   this.noOfUserProfilesLimit = this.appConfig.defaultProfileLimit;
    // }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

    if (this.subscriberProfileDetails) {
      this.subscriberProfileDetails = JSON.parse(this.subscriberProfileDetails);
    }
  },

  mounted() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function(event) {
    window.history.pushState(null, null, window.location.href);
  };
    document.body.style.position = "fixed"

    let renderCancelIcon = this.$route.params.renderCancel;
    if(renderCancelIcon !== null && renderCancelIcon !== undefined){
      this.renderCancel = renderCancelIcon;
    } else {
      this.renderCancel = true;
    }
    if (this.$route.query.islogin) {
      this.renderCancel = false
    }

    if(this.mode !== null && this.mode === "addProfile"){
      this.goToAddProfile();
    }

    setTimeout(() => {
      let header =  document.getElementById("headerMobilePhoneWise");
      header.classList.add('display-none');
    }, 50);

   this.isLoading = true;
    this.listProfiles()
      .then((response) => {
        if(response.data) {
          let IndexItem = 0;
          let lists = response.data.data;
          for (let index in lists) {
            if (lists[index].profileid == this.subscriberid) {
              IndexItem = index;
            } 
          }

         let adminArr = lists.splice(IndexItem, 1);

          this.profilesList = [...adminArr, ...lists]

          
          localStorage.setItem("profile-list",JSON.stringify(this.profilesList));

          this.isLoading = false;
        }
      })
      .catch((err) => {
        this.isLoading = false;
      });
  },

  methods: {
    ...mapActions(["listProfiles", "switchProfile", "fcmMessagingUnsubscribeToTopic", "fcmMessagingSubscribeToTopic", "sendProfilePinOTP", "resetProfilePin"]),

    ...mapMutations(["setProfileid"]),
    actClosePopup () {
        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({ name: "Home", params: { lang: currentLanguage } });
    },
    goToAddProfile() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilepin === "ENABLED") {
        if(this.mode !== null && this.mode === "addProfile"){
          this.$router.replace({ name: "addProfile" , params: { lang: currentLanguage }});
        } else {
          this.$router.push({ name: "addProfile" , params: { lang: currentLanguage }});
        }
        // this.isAddProfile = true;
      } else if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilepin !== "ENABLED") {
        console.log("Block 2");

        this.selectedProfile = this.subscriberProfileDetails;
        this.isSetNewPin = true;
        this.showSetOrVerifyProfilePin = true;
      } else {
        if(this.mode !== null && this.mode === "addProfile"){
          this.$router.replace({ name: "addProfile", params: { lang: currentLanguage } });
        } else {
          this.$router.push({ name: "addProfile", params: { lang: currentLanguage } });
        }
      }
    },

    onActionForgotPin(profile){
      this.forgotPinProfileContext = profile;
      this.showSwitchProfilePin = false;
      
      this.loading = true;
      let payload ={
        profileid: profile.profileid
      }
      this.sendProfilePinOTP(payload)
      .then((data) => {
          this.loading = false;
          if (data.data.reason) {
            showSnackBar(this.$t(data.data.reason),this.$t('DISMISS'));
            return;
          } else if (data.data.success) {
            this.showForgotPinModal = true;
          }
        })
      .catch((error)=> {
        console.log("reset pin send otp error => ", JSON.stringify(error));
      });
    },
    closeForgotPinModal(){
      this.showForgotPinModal = false;
      this.pinError = "";
      this.loading = false;
    },
    onForgotPinModalActionComplete(payload){
      let OTP = payload.otp;
      let pin = payload.pin;
      this.loading = true;
      let data = {
        otp: OTP,
        pin: pin
      };
      this.resetProfilePin(data).then((response) => {
        this.loading = false;
        if(response.data.errorcode === 7311){
          this.pinError = this.$t(response.data.reason);
          return;
        }
        this.showForgotPinModal =false;
        this.pinError = "";
        showSnackBar(this.$t("PIN reset successfully"),this.$t('DISMISS'));
      } ).catch(
        (error) => {
          console.log("reset pin error => ", JSON.stringify(error));
        }
      )

    },
    resendOtp(){
      this.loading = true;
      let payload ={
        profileid: this.forgotPinProfileContext.profileid
      }
      this.sendProfilePinOTP(payload).then((data) => {
        this.loading = false;
          if (data.data.reason) {
            showSnackBar(this.$t(data.data.reason),this.$t('DISMISS'));
            this.showForgotPinModal = false;
            return;
          } else if (data.data.success) {
            this.showForgotPinModal = true;
             showSnackBar(this.$t("OTP sent on registered email/mobile"),this.$t('DISMISS'));
          }
        // this.loading = false;
        // showSnackBar(this.$t("OTP sent on registered email/mobile"));
      })
      .catch((error)=> {
        console.log("reset pin send otp error => ", JSON.stringify(error));
      });
    },
    // .then((data) => {
    //       this.loading = false;
    //       if (data.data.reason) {
    //         showSnackBar(this.$t(data.data.reason));
    //         return;
    //       } else if (data.data.success) {
    //         this.showForgotPinModal = true;
    //       }
    //     })
    closeProfilePinPopupEvent() {
      this.showSwitchProfilePin = !this.showSwitchProfilePin;
    },

    closeSetOrVerifyProfilePin() {
      this.showSetOrVerifyProfilePin = !this.showSetOrVerifyProfilePin;
    },

    selectProfile(profile) {
      this.isProfileLoading = true;
			this.currentProfileIdAdminCheck = localStorage.getItem("currentProfileIdAdminCheck");
      let currentLanguage = this.getCurrentLanguageUrlBase();

			if(profile.profileid == this.currentProfileIdAdminCheck){

        let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
      if (currentLanguage == defaultLanguage) {
          currentLanguage = null;
        }
        this.$router.push({ name: "Home", params: { lang: currentLanguage } });

			return;
			}
      if (profile.profilepin === "ENABLED") {
        this.selectedProfile = profile;
        this.isProfileLoading = false;
        this.showSwitchProfilePin = true;
      } else {
        const profilePayload = {
          profileid: profile.profileid,
        };

        if(profile.kidsmode === "YES") {
          this.unSubscribeToNotification();
        } else {  
          this.subscribeToTopic();
        }

        this.switchProfile(profilePayload)
          .then((response) => {
            if (!response.data.reason) {
              localStorage.setItem("sessionToken", response.data.success);
              localStorage.setItem("refreshtoken", response.data.refreshtoken);
              this.isProfileLoading = false;
              try {
              actSaveExpireTime(jwt_decode(response.data.success).expiresIn)  
              localStorage.setItem("refresh-token-expire", jwt_decode(response.data.success).expiresIn);
              getFreshTokenUpdate();

            } catch (e) {} 

              localStorage.setItem("currentProfileId", profilePayload.profileid);
							localStorage.setItem("currentProfileIdAdminCheck", profilePayload.profileid);
              localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));

              localStorage.setItem("isKidsMode", profile.kidsmode === "YES");
              this.setProfileid(profilePayload.profileid);
          


            // this.$router.push({path: window.storeAfterLoginPath ? window.storeAfterLoginPath : '/' });
            // window.storeAfterLoginPath = "";
            if ( window.selectedPlanInfomationRouting && !(this.subscriptionList && this.subscriptionList.length > 0)) {

                   this.$router.push({ name: "Home", params: { lang: currentLanguage } });
                    setTimeout (() => {
                      eventBus.$emit("open-home-rzp-modal", window.selectedPlanInfomationRouting);
                      window.selectedPlanInfomationRouting = "";
                    }, 500)
                } else {
                  this.$router.push({path: window.storeAfterLoginPath ? window.storeAfterLoginPath : currentLanguage ? '/'+ currentLanguage : '/' });
                }

                window.storeAfterLoginPath = ""
         

            } else {
              showSnackBar(this.$t(response.reason),this.$t('DISMISS'));
              this.isProfileLoading = false;
            }
          })
          .catch((err) => {
            this.isProfileLoading = false;
          });
      }
    },

    unSubscribeToNotification() {
      this.fcmMessagingUnsubscribeToTopic(this.subscriberid);
    },

    subscribeToTopic() {
      this.fcmMessagingSubscribeToTopic(this.subscriberid);
    }
  },
  mixins: [Utility],
  beforeDestroy() {
    document.body.style.position = ""
    window.onpopstate = null;
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.close-icon-switch-profile {
  position: absolute;
  right: 10vw;
  cursor: pointer;
}
.loader-margin {
  margin: 2rem 0 0 0;
}

.switchProfileContainer {
  // position: absolute;
  // top: 20%;
  margin-top: 7rem;
  width: 95vw;
  height: 75vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .whosWatching {
    letter-spacing: 0px;
    color: $clr-whowtching-text;
    opacity: 1;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: $font-regular;
    text-align: center;
  }
  .switchProfileCard {
    width: 60vw;
    margin-top: 3rem;
    display: flex;
    //flex-wrap: wrap;
    justify-content: space-around;
    .user-profile {
      display: flex;
      align-items: center;
      flex-direction: column;

      cursor: pointer;

      .userImage {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .userName {
        margin-top: 1rem;
        opacity: 1;
        font-size: 1.2rem;
        font-weight: 600;
        font-family: $font-regular;
        color: $clr-dark-gd10;
      }
      .userAsAdmin {
        margin-top: 1rem;
        opacity: 1;
        font-size: 0.9rem;
        font-weight: 600;
        font-family: $font-regular;
        background: linear-gradient(to right, #003AAC, #0073fa, #0443c4);;
        padding: 5px;
        text-align: center;
        color: $clr-user-admin-txt;
        border-radius: 4px;
      }
      .userLable {
        margin-top: 1rem;
        opacity: 1;
        font-size: 0.9rem;
        font-weight: 600;
        font-family: $font-regular;
        background-color: $clr-user-label;
        padding: 5px;
        text-align: center;
        color: black;
        border-radius: 4px;
      }
      .addProfileImage {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 576px) {
  .switchProfileContainer {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85vh !important;
    .whosWatching {
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      font-size: 1.2rem;
      font-weight: 600;
      font-family: $font-regular;
      text-align: center;
    }
    .switchProfileCard {
      width: 360px;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2rem;
      // flex-wrap: wrap;
      .user-profile {
        margin-top: 1.2rem;
        // margin-right: 1rem;
        display: flex;
        margin-left: 0rem;
        // margin-right: 1rem;

        //  flex-direction: column;
        .userImage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        .userName {
          margin-top: 0.8rem;
          opacity: 1;
          font-size: 1rem;
          font-weight: 600;
          font-family: $font-regular;
          color: $clr-swtchprofilecard-txt;
        }
        .userLable {
          margin-top: 1rem;
          opacity: 1;
          font-size: 0.9rem;
          font-weight: 600;
          font-family: $font-regular;
          background-color: $clr-user-label;
          padding: 5px;
          text-align: center;
          color: black;
          border-radius: 4px;
        }
        .addProfileImage {
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          // padding-bottom: 4rem;
          // margin-left:4rem;
        }
        //  .userNameAddProfile{
        //    margin-left: 2rem;
        //    margin-top: 0.8rem ;
        //    opacity: 1;
        //   font-size: 1rem;
        //   font-weight: 600;
        //   font-family: $font-regular;
        //   color: $clr-dark-gd10;
        //  }
      }
      //justify-content: space-between;
    }
  }
}

.navMenuHeader{
  display: none !important;
}
</style>
